import React from 'react';
import { EditButton } from 'react-admin';


const AppEditButton = ({ record }) => {
    if (record != undefined) {
        if (record.Status != undefined && record.Status === "Submitted") {
            return (
                <EditButton basePath="/Applications" record={record} />
            );
        } else {
            return "";
        }

    } else {
        return "";
    }
}

export default AppEditButton;