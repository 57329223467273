import React, { Component, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import styles from './styles.module.css';
import { useInput } from 'react-admin';


const Signature = (props) => {
    const {
        input,
    } = useInput(props);

    const sigPad = useRef(null)

    const clear = () => sigPad.current.clear();
    const handleChange = () => {
        input.onChange(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
    }

    return (
        <div className={styles.container} >
            <div className={styles.sigContainer}>
                <SignaturePad ref={sigPad} label="Signature" canvasProps={{ width: "200px", height: "70px", }} onEnd={handleChange} />
                {/* {console.log("sigpad", sigPad)} */}
            </div>
            <div>
                <button type="button" className={styles.buttons} onClick={clear}>
                    Clear
                </button>
            </div>
        </div >
    )
}

export default Signature;