import React from 'react';
import { Filter, BulkDeleteButton, TopToolbar, List, Edit, Datagrid, TextField, EditButton, ReferenceInput, SelectInput, SimpleForm, TextInput, required, } from 'react-admin';
import { useMediaQuery, Typography } from '@material-ui/core';
import ActionsRefresh from './NoAction.js';



const Aside = ({ data, ids }) => {
    // console.log(data, ids);
    var numrcpt = ids.map(id => data[id]).reduce((numrcpt, rec) => {
        return numrcpt + 1
    }, 0);
    var tottut = ids.map(id => data[id]).reduce((tottut, rec) => {
        var tut = parseFloat(rec.Amt)
        var ramt = parseFloat(rec.Regamt)
        var mamt = parseFloat(rec.Materialamt)
        var ehrs = parseFloat(rec.ExtraHrs)
        var lFee = parseFloat(rec.LateFee)
        if (!isNaN(tut)) {
            if (!isNaN(ehrs)) {
                tut = tut + ehrs
            }
            if (!isNaN(lFee)) {
                tut = tut + lFee
            }
            return tottut + tut
        } else if (!isNaN(ramt)) {
            return tottut + ramt
        } else if (!isNaN(mamt)) {
            return tottut + mamt
        }
    }, 0.0);
    return (
        <div style={{ width: 200, margin: '1em' }} >
            <Typography >Details:</Typography>
            <Typography variant="body1">
                <br />
                <Typography ># of receipts</Typography>
                {numrcpt}
                <br />
                <Typography >Total Amount</Typography>
                ${tottut}
            </Typography>
        </div >
    );
}



const ReceiptFilter = (props) => (
    <Filter {...props}>
        <SelectInput alwaysOn label="Month" validate={required()} source="Month" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 0, name: 'Jan' },
            { id: 1, name: 'Feb' },
            { id: 2, name: 'Mar' },
            { id: 3, name: 'Apr' },
            { id: 4, name: 'May' },
            { id: 5, name: 'Jun' },
            { id: 6, name: 'Jul' },
            { id: 7, name: 'Aug' },
            { id: 8, name: 'Sep' },
            { id: 9, name: 'Oct' },
            { id: 10, name: 'Nov' },
            { id: 11, name: 'Dec' },
        ]} />
        <SelectInput alwaysOn label="Year" validate={required()} source="Year" allowEmpty={false} choices={[
            { id: 2020, name: '2020' },
            { id: 2021, name: '2021' },
            { id: 2022, name: '2022' },
            { id: 2023, name: '2023' },
        ]} />
        <SelectInput alwaysOn label="Type" validate={required()} source="RcptType" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'registration', name: 'registration' },
            { id: 'materialfee', name: 'materialfee' },
            { id: 'tuition', name: 'tuition' },
        ]} />
        <SelectInput alwaysOn label="Paid" validate={required()} source="Paid" allowEmpty={false} choices={[
            { id: 'All', name: 'All' },
            { id: 'Y', name: 'Y' },
            { id: 'N', name: 'N' },
        ]} />
        <ReferenceInput allowEmpty={false} alwaysOn label="Student ID" source="id" reference="Students" perPage="-5">
            <SelectInput optionText={(record) => { let val = record.Sname; return val; }} />
        </ReferenceInput>
        {/* <TextInput alwaysOn label="Student ID" source="SID" /> */}
    </Filter >
);

// export const ReceiptCreate = (props) => (
//     <Create {...props}>
//     <SimpleForm>
//     <SelectInput source="Month" choices={[
//         { id: '1', name: 'January' },
//         { id: '2', name: 'Feburary' },
//     ]} />
//     </SimpleForm>
//     </Create>
// );

var d = new Date();
var mnth = d.getMonth();
var yr = d.getFullYear();
console.log(mnth)

export const ReceiptList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={<ReceiptFilter />} aside={<Aside />} filterDefaultValues={{ Month: mnth, Year: yr, RcptType: "All", Paid: "All" }} bulkActionButtons={false}  actions={<ActionsRefresh {...props} />} {...props}>
            {isSmall ? (
                // <SimpleList
                //     primaryText={record => record.StudentName}
                //     secondaryText={record => record.Amt}
                //     tertiaryText={record => record.Paid}
                // />
                <Datagrid>
                    <TextField label="Student Name" source="StudentName" />
                    <TextField label="Amount" source="Amt" />
                    <TextField label="Billing Date" source="BillingDate" />
                    <TextField label="Type" source="Rcpttype" />
                    <TextField label="Extra Hours" source="ExtraHrs" />
                    <TextField label="Month" source="Month" />
                    <TextField label="Year" source="Year" />
                    <TextField label="Paid" source="Paid" />
                </Datagrid>
            ) : (
                <Datagrid>
                    <TextField label="Student Name" source="StudentName" />
                    <TextField label="Amount" source="Amt" />
                    <TextField label="Reg Amount" source="Regamt" />
                    <TextField label="Material Amount" source="Materialamt" />
                    <TextField label="Billing Date" source="BillingDate" />
                    <TextField label="Paid Date" source="PaidDate" />
                    <TextField label="Type" source="Rcpttype" />
                    <TextField label="Extra Hours" source="ExtraHrs" />
                    <TextField label="Registration Year" source="RegYear" />
                    <TextField label="Month" source="Month" />
                    <TextField label="Year" source="Year" />
                    <TextField label="Paid" source="Paid" />
                </Datagrid>)}
        </List>
    );
};

const ReceiptTitle = ({ record }) => {
    return <span>Receipt {record ? `"${record.id}"` : ''}</span>;
};

