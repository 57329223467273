import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import history from "./history";


const redirectURL = process.env.REACT_APP_AUTH0_REDIRECT_URL
const clientID = process.env.REACT_APP_AUTH0_CLIENTID
// const onRedirectCallback = (appState: any) => {
//   if (appState != undefined) {
//   history.push(
//     appState.targetUrl  );
//   } else {
//     history.push(
//       window.location.pathname
//     );

//   }
//   console.log("HISTAPPST",history.location.pathname);
// };
// onRedirectCallback();
var redirectPath = "/";
if (window.location.pathname !== undefined && window.location.pathname !== "" && window.location.pathname !== "/") {
  redirectPath = window.location.pathname
} 
console.log("PATH",redirectPath);
ReactDOM.render(
  < Auth0Provider
    domain="lamsaz.us.auth0.com"
    clientId={clientID}
    // onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    // redirectUri={redirectURL}
    redirectUri={redirectURL+redirectPath}
    audience="https://www.lamsaz.com:8080"
    scope="user"
  >
    <App />
  </Auth0Provider >, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

