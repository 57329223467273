import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Labeled, RichTextField, RecordContextProvider, useRefresh, useRedirect, useNotify, DateInput, SimpleForm, TextInput, required, TextField } from 'react-admin';
import MyTab from './mytab.js';
import Signature from './sign.js';
import DisplaySignature from './dispsignature.js';
import { Terms } from './employeeTerms.js';


var fetching = false;
var egrJson;

export const EmployeeDashboard = ({ ...permissions }) => {
    const [egrExists, setEgrExists] = useState(false);

    var termsTxt;
    var ver;
    const notify = useNotify();
    const fetchURLEmplGenRelease = process.env.REACT_APP_GO_CONNECT_URL + "/EmployeeGenReleases"
    if (permissions !== undefined) {
        termsTxt = Terms[permissions.role].termstxt;
        ver = Terms[permissions.role].version;
    } else {
        console.log("Perms undefined", permissions);
    }
    useEffect(() => {
        const container = document.getElementById('termtxt');
        if (container != null) {
            container.innerHTML = termsTxt;
        }
    });


    const handleSave = (values) => {
        values.Terms = termsTxt;
        values.Version = ver;
        // console.log(values)

        var bdy = JSON.stringify(values);
        const token = localStorage.getItem('token');
        var hdrs = new Headers({
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
            "Content-Length": JSON.stringify(bdy).length
        })
        fetch(fetchURLEmplGenRelease, { method: 'POST', body: bdy, headers: hdrs })
            .then(response => {
                // console.log(response);
                if (response.ok) {
                    response.json().then(json => {
                        window.location.reload();
                    });
                } else if (response.status === 401) {
                    notify("Unauthorized, please login...", "error");
                } else if (response.status === 500) {
                    notify("Internal Error, please try again or report this error to LAMS", "error");
                } else {
                    response.json().then(json => {
                        // console.log(response, json);
                        if (json != "") {
                            notify("Error: " + json.message, "error");

                        } else {
                            notify("Error generating employee general release", "error");
                        }
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                notify('Error: Unable to create employee general release', 'error');
            });


    };

    useEffect(() => {
        const getEemployeeGenRelease = () => {
            fetching = true;
            const token = localStorage.getItem('token');
            var hdrs = new Headers({
                "Authorization": "Bearer " + token,
            })
            fetch(fetchURLEmplGenRelease + "?Version=" + ver, { method: 'GET', headers: hdrs })
                .then(response => {
                    // console.log(response);
                    if (response.ok) {
                        response.json().then(json => {
                            // console.log("Appln",response, json);
                            setEgrExists(true);
                            egrJson = json;
                        });
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        };
        getEemployeeGenRelease();
    }, []);

    return (
        <div>
            {egrExists ? (
                <Box p="1em">
                    <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Employee Dashboard</h1></span>
                    <br /><br />
                    <Box >
                        <h3>Duties & Responsibilities</h3>
                        <Box>
                            <Box style={{ width: "100%" }}>
                                <RecordContextProvider value={egrJson}>
                                    <RichTextField source="Terms" label="Duties & Responsibilities" multiline />
                                    <Labeled label="Name">
                                        <TextField source="Name" />
                                    </Labeled><MyTab />
                                    <Labeled label="Date" >
                                        <TextField source="Date" />
                                    </Labeled>
                                    <Box style={{ width: "100%" }}>
                                        <span>Signature:</span>
                                        <DisplaySignature disabled label="Signature" source="Signature" />
                                    </Box>
                                </RecordContextProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box p="1em">
                    <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Employee Dashboard</h1></span>
                    <br /><br />
                    <Box >
                        <h3>Duties & Responsibilities</h3>
                        <Box>
                            <SimpleForm save={handleSave}>
                                <Box style={{ width: "100%" }}>
                                    <div id="termtxt"></div>
                                    <br />
                                    <TextInput label="Name" source="Name" validate={required()} /><MyTab />
                                    <DateInput label="Date" source="Date" validate={required()} />
                                    <Box style={{ width: "100%" }}>
                                        <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                        <Signature source="Signature" validate={required()} />
                                    </Box>
                                </Box>
                            </SimpleForm>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    );
};
export default EmployeeDashboard;