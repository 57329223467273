// in src/students.js
import React from 'react';
// import TuitionReceiptButton from './TuitionReceiptButton';
// import AnnualReportButton from './AnnualReportButton';
// import RegReceiptButton from './RegReceiptButton';
// import MaterialReceiptButton from './MaterialReceiptButton';
// import LateFeeInvoiceButton from './LateFeeInvoiceButton';
// import GenericInvoiceButton from './GenericInvoiceButton';
// import CreateDraftEmailButton from './CreateDraftEmailButton';
// import SendWelcomeEmailButton from './SendWelcomeEmailButton';
import { SimpleList, RefreshButton, ExportButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, Filter, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
// import ExtraHrsButton from './ExtraHrsButton';
// import CashSummaryButton from './CashSummaryButton';
// import GenerateRCsButton from './GenerateRCsButton';
import { useMediaQuery } from '@material-ui/core';
// import StudentName from './studentName.js';
// import SignInButton from './SignInButton.js';
// import SignOutButton from './SignOutButton.js';
// import UpdateTRCommonData from './UpdateTRCommonData';
import StudentAbsence from './StudentAbsence.js';
import TimeParse from './timeparse.js';
import ActionsRefresh from './NoAction.js';
import useAnalyticsEventTracker from './analyticsTracker.js';




const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validatePIN = [required(), regex(/[0-9][0-9][0-9][0-9]/, "Must be 4 digit number")];
const validatePrg = [required()];
// const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDate = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateClass = [required()];
const validateSex = [required()];
const validateEStatus = [required()];





const StudentAbsenceFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput allowEmpty={false} alwaysOn label="Student Name" source="id" reference="Students" perPage="-5">
            <SelectInput optionText="Sname" />
        </ReferenceInput>
    </Filter>
);


export const StudentAbsencesList = ({ permissions, ...props }) => {
    const gaEventTracker = useAnalyticsEventTracker('Student Absences');
    gaEventTracker('Student Absences List');
    if (permissions !== undefined && permissions.role === 'user') {
        return (
            <div>
            <div>Please call LAMS office to remove any absences</div>
            <List  {...props} bulkActionButtons={false} filters={<StudentAbsenceFilter />}  sort={{ field: 'Sname', order: 'ASC' }} actions={<ActionsRefresh {...props} />} >
                {(
                    <Datagrid >
                        <TextField label="From" source="FromDt" />
                        <TextField label="To" source="ToDt" />
                        <TextField label="Reason" source="Reason" />
                        <TextField label="Type" source="Type" />
                        <TimeParse label="Time" source="Time" />
                    </Datagrid>)}
            </List>
            </div>
        );
    } else {
        return (<span>User has No Access...</span>)
    }
};





const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const UserListActions = (props) => {
    // console.log("In student buttons ", props);
    // let st = props.filterValues.EStatus;
    // let cl = props.cl;
    // console.log("Classroom filter ", cl, st);
    return (
        <TopToolbar>
                    {/* <BulkDeleteButton {...props} undoable="true" /> */}
        </TopToolbar>
    );
}
