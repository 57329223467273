
import { Auth0Client } from '@auth0/auth0-spa-js';
const jwt = require('jsonwebtoken');

const clientID = process.env.REACT_APP_AUTH0_CLIENTID

const authProvider = {

    login: async (params) => {
        // console.log("calling get token", params);
        if (params) {
            const { user, token } = params;
            if (user != undefined) {
                // console.log("After auth0 login",user)
                var perm = "user" //default
                var classroom = "";
                var decode = jwt.decode(token);
                // console.log("JWT decoded");
                if (decode.permissions.length >= 1) {
                    var key = decode.permissions[0].split(":");
                    if (key[0] === "r") {
                        perm = key[1];
                    }
                    if (decode.permissions.length === 2) {
                        key = decode.permissions[1].split(":");
                        if (key[0] === "c") {
                            classroom = key[1];
                        }
                    }
                }
                const role = JSON.stringify({
                    'role': perm,
                    'classroom': classroom
                });
                localStorage.removeItem('not_authenticated');
                // localStorage.setItem('not_authenticated', false);
                localStorage.setItem("token", token);
                localStorage.setItem("userID", params.user.sub);
                localStorage.setItem('role', role);
                // console.log("Login Resolved ",role);
                return Promise.resolve();
            } else {
                localStorage.setItem('not_authenticated', true);
                return Promise.reject({ message: false });
            }
        } else {
            localStorage.setItem('not_authenticated', true);
            return Promise.reject({ message: false });
        }
    },
    logout: params => {
        // console.log("In AuthProvide logout",params);
        localStorage.setItem('not_authenticated', true);
        localStorage.removeItem("token");
        localStorage.removeItem("userID");
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    checkAuth: params => {
        let notloggedin = localStorage.getItem('not_authenticated');
        // console.log("In AuthProvide checkAuth",notloggedin,params);
        if (notloggedin == undefined || notloggedin == null ) {
            notloggedin = false;
        }
        // localStorage.removeItem('not_authenticated');
        // console.log("In check Auth ", loginstatus);
        return notloggedin
            ? Promise.reject({ message: false })
            : Promise.resolve();
    },
    checkError: error => {
        // console.log("In AuthProvide Error");
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.setItem('not_authenticated', true);
            localStorage.removeItem("token");
            localStorage.removeItem("userID");
            localStorage.removeItem('role');
            return Promise.reject({ message: false });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: params => {
        const role = JSON.parse(localStorage.getItem('role'));
        // console.log("Getting permissions ",role,params);
        return Promise.resolve(role);
    }
};

export default authProvider;