import React from 'react';
import { changeLocaleSuccess, Login } from 'react-admin';
import { withStyles, createStyles } from '@material-ui/core/styles';

import LoginForm from './LoginForm';

const styles = () =>
    createStyles({
        card: {
            background: 'url(https://lamsaz.com/images/LAMSLogo.jpg)',
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'contain',
            backgroundColor: 'white',
            // height: '200px',
            // width: '500px',
        }
    });


const LoginPage = (props) => { return (<Login {...props} ><LoginForm /></Login>) };

export default withStyles(styles)(LoginPage);
