import { Box, Typography, CardActions } from '@material-ui/core';

const ActionsRefresh = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter = false, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate = false, // you can hide CreateButton if hasCreate = false
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <CardActions>
    </CardActions>
);

export default ActionsRefresh;