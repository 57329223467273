import React from 'react';

const TimeParse = ({ record, type }) => {
    // const tm = Date.parse(record.Time);
    if (record.Time != "") {
    const tm = new Date(record.Time)
    // console.log("TM", tm)
    return (
        tm.toLocaleTimeString()
    );
    } else {
        return ("");
    }
}

export default TimeParse;