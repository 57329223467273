import { Box } from '@material-ui/core';


export const StudentDashboard = ({ ...permissions }) => {
    return (
        <Box p="1em">
            <span style={{ textAlign: 'center' }}><h1>Welcome to LAMS Parent Dashboard</h1></span>
            <br /><br />
            <Box >
                You can perform the following tasks:
                <ol>
                    <li>Check Student(s) details</li>
                    <li>Submit New Student Application for Enrollment Or check status of existing Application</li>
                    <li>Submit Student Absence(s). To submit Absence please click on <a href="https://user.lamsaz.com/Students">Students link</a> on left navigation and click the green button in the student details.</li>
                    <li>Check Student Absence submitted for a given date.</li>
                    <li>Check Student Attendance for a given day</li>
                    <li>For any questions please refer to <a href="https://lamsaz.com/faqs/index.html" target="_blank">LAMS FAQs</a></li>
                </ol>
            </Box>
        </Box>
    );
}