

export const Terms = {
    primaryassistant: {
        termstxt: 'As an employeee of LAMS, I have read and understood the duties & responsibilities of an Assistant for Primary classroom and will perform, adhere & execute them to the best of my abilities.\
        <ol>\
        <li>Follow Montessori way of teaching.</li>\
        <li>Organize, oversee and support all day-to-day activities of the assigned classroom and or area of teaching.</li>\
        <li>Assist the teacher in conducting staff/parent conferences.</li>\
        <li>Maintains a peaceful and prepared environment to foster student directed learning.</li>\
        <li>Maintains a clean and attractive learning environment associated with the Montessori philosophy.</li>\
        <li>Responsible for supervising students in the absence of the Head Teacher, including lunch and/or recess duties.</li>\
        <li>Attend and participate in school functions, including monthly staff meetings, workshops etc.</li>\
        <li>Responsible for safety of the children for assigned classroom and school in general at all times during the work hours.\
        <li>Bring to immediate attention of Class Teacher/Office Manager any injury/harm to a child.</li>\
        <li>Prepare and send injury report to Parents on the same day.</li>\
        <li>Report any behavior issue to Class Teacher and help the teacher prepare and sent updates to Parent of the child.</li>\
        <li>Report to Class Teacher about classroom activities and progress of children on regular basis.</li>\
        <li>Provide Class Teacher with class updates.</li>\
        <li>Maintain student records for the classroom assigned.</li>\
        <li>Maintain 12-18hrs of professional development as mandated by ADHS for a given school year.</li>\
        <li>Be punctual as per school timings.</li>\
        <li>Bring any parent concern or issue to the notice of Class Teacher immediately as and when they become aware of it.</li>\
        <li>Shall perform such other duties as from time to time determined by the School.</li>\
        <li>Shall perform their role at School faithfully, loyally and honestly.</li>\
        <li>Will devote substantially all of their work time to performing duties for, and engaging in the business and affairs of, the School.</li>\
        </ol>\
        <div style="font-size: 1.25em"><b>Detailed Tasks</b></div><br>\
        <u>In the morning 7:20am onwards (in the assigned classroom)</u><br>\
        <b>Indoor Environment</b>\
        <ul>\
        <li>Take the chairs in the classroom down.</li>\
        <li>Wipe the tables with bleach free wipes</li>\
        <li>Check the garbage, if the bag is puffed up, poke a tiny hole near the top to let out air.</li>\
        <li>Take the plates and silverware out of the dishwasher and put in the classroom cart.</li>\
        <li>Fold the washcloths and aprons and put into their respective baskets.</li>\
        <li>Fill the white baskets by the sink with paper towels for the children who can’t reach the paper towel dispensers. Help In teaching the children to use only one paper towel at a time. </li>\
        <li>As they arrive in the morning, 2 to 4 children should put on aprons and start dusting before the playground time. Children who are already dusting</li>\
        <li> can continue to dust before going to the playground at 8:30am</li>\
        <li>Fill up the water in the water works and wet the sponges.</li>\
        <li>Put out the table cloth for the snack table and lay out two placemats (to be cleaned up by 10:40am- table cloth taken away and put into the laundry basket in the bathroom)</li>\
        <li>Fill the classroom water dispenser( for children to use to fill up their water bottles)</li>\
        <li>Water the class plants</li>\
        <li>Sharpen pencils/colored pencils (can be done at the end of the day as well)</li>\
        <b>*Inside voice to be modeled by the adult, no unnecessary talking to the children while they are working to avoid disrupting their work*</b>\
        <li>At the end of the day, wipe down the chairs and tables with the bleach free disinfectant wipes.</li>\
        <b>At 8:05, children line up by the back door to go to the playground</b>\
        </ul>\
        <u>In the morning 7:20am onwards (in the assigned classroom)</u><br>\
        <b>Outdoor Environment</b><br>\
        <ul>\
        <li>Take the carrots out and the bowl, the peeler and crinkle cutter, and chopping board out of the dishwasher to the patio and set on the carrot work table.</li>\
        <li>Playdough work to be prepped in the morning.</li>\
        <li>Dust the shelves and make sure the environment looks appealing. (shelves and works are straightened out and in their correct spots)</li>\
        <li>Take down chairs and set them up at each table.</li>\
        <li>Fill the water in each water work and wet each sponge.</li>\
        <li>Burlap needs to be replenished in the sewing works.</li>\
        <li>Yarn, needles, and needle threader need to be checked to make sure they are all present for the sewing works.</li>\
        <li>Easel to be cleaned, paint brushes and paint cups cleaned and ready for the next day.</li>\
        <li>Colored pasta and necklace yarn to be ready in advance.</li>\
        <li>Make sure patio is clean after every morning work cycle.</li>\
        <b>*Inside voice to be modeled by the adult, no unnecessary talking to the children while they are working to avoid disrupting their work*</b>\
        </ul>\
        <b>Playground</b>\
        <ul>\
        <li>*Half day Dismissal children to be brought to the office by 12:25 to be handed over to Ms.Cassie*</li>\
        <li>Assistants should be walking around the playground area to make sure all children are visible.</li>\
        <li>Attendance roster needs to be taken outside to the playground</li>\
        <li>No child should be sent to the bathroom unattended, a teacher needs to accompany them to the classroom.</li>\
        <b>*Once children return from the playground, and throughout the morning work cycle, two children (up to four) can alternate dusting*</b>\
        </ul>\
        <b>Lunch time Prep (10:50am)</b>\
        <ul>\
        <li>Ask children to help with setup, ask one child to:</li>\
        -set the placemats on each table\
        -another to set out the napkins on each placemat\
        -bring the chairs in from the patio needed for lunch\
        <li>Bring in the lunchboxes from the refrigerator into the classroom and place by the shelf.</li>\
        <li>Bring in the extra tables needed for lunch</li>\
        <li>Set out the two white basins (one for placemats and one for dirty plates/silverware) by the farm table. Two wet washcloths and two dry washcloths to be kept by the basins.</li>\
        <li>Ask one child to set out the water bottles on each table</li>\
        </ul>\
        <b>11:30 am (Lunch time and onwards)</b>\
        <ul>\
        <li>Assist in getting the handwashing done before the children sit down for lunch.</li>\
        <li>Assist as required for opening the lunch boxes and warm ups of food.</li>\
        <li>No sharing of food allowed.</li>\
        <li>Noise level stays low while children are eating lunch.</li>\
        </ul>\
        <b>Clean Up After Lunch</b>\
        <ul>\
        <li>Children clean up their placemats as well as tables with first the wet washcloth, then the dry washcloth.</li>\
        <li>Placemats go into one of the basins to be wiped down.</li>\
        <li>After they are wiped down, clean placemats go onto the tray on the cart.</li>\
        <li>Dirty dishes and silverware go into the other white basin.</li>\
        <li>Children use the small brushes and the dust pans to clean the area around their table.</li>\
        <b>*assistants can assist by making small piles so that it becomes easy for the children to sweep up*</b>\
        <li>Make sure the cart is back in its place for tomorrow.</li>\
        <li>Children should put the lunch boxes back onto their shelf, make sure shelf is tidy after the children have done so.</li>\
        <li>One assistant takes two children (with aprons on) to the kitchen to do the dishes.</li>\
        <li>After cleaning is done, children sit on the carpet ready for the playground.</li>\
        </ul>\
        <b>Nap Room</b>\
        <ul>\
        <li>Nap room assignee of the day should prepare the room in the morning (put out mats)</li>\
        <li>Nappers need to have gone to the bathroom, and washed hands and be lined up by the door for nap time.</li>\
        <li>Materials should be made during nap time. (cutting work, necklace work, monthly materials for project)</li>\
        <li>Nappers should be in the nap room by 1:00pm the latest, and wake up between 2:15-2:20.</li>\
        </ul>\
        <b>Afternoon Work Cycle and Dismissal</b>\
        <ul>\
        <li>Children clean up their respective works at the end of the work cycle.</li>\
        <li>Children then sit down on the carpet for afternoon group time.</li>\
        <li>Assistants clean the classroom while children are sitting for afternoon group time- put the dishes in the dishwasher from the works used.</li>\
        <li>Cover the patio area.</li>\
        <li>Empty laundry basket from bathroom and put into the washer.</li>\
        <li>Stock up on materials for works (paper for metal inset, number papers)</li>\
        <li>Fix the materials on the shelf (Indoor and patio for each respective Assistant)</li>\
        <li>After all the children have gone, make sure the lamp in the library area is off</li>\
        </li>\
        </ul>\
        </ol>',
        version: 1
    },
    toddlerassistant: {
        termstxt: '<ol><li>TA I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li><li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li><li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li><li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li><li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li><li>Children attending LAMS(for primary program) are required to be fully potty trained.</li><li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li><li>We understand balances due must be paid before evaluations can be released.</li><li>Fee once paid is not refundable under any circumstances.</li><li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li></ol>',
        version: 1
    },
    teacher: {
        termstxt: '<ol><li>T I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li><li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li><li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li><li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li><li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li><li>Children attending LAMS(for primary program) are required to be fully potty trained.</li><li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li><li>We understand balances due must be paid before evaluations can be released.</li><li>Fee once paid is not refundable under any circumstances.</li><li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li></ol>',
        version: 1
    },
    toddlerteacher: {
        termstxt: '<ol><li>TT I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li><li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li><li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li><li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li><li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li><li>Children attending LAMS(for primary program) are required to be fully potty trained.</li><li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li><li>We understand balances due must be paid before evaluations can be released.</li><li>Fee once paid is not refundable under any circumstances.</li><li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li></ol>',
        version: 1
    }

}