import {
  SaveButton,
  DateInput,
  useCreate,
  required,
  SelectInput,
  FormWithRedirect, TextInput, minValue, showNotification as showNotificationAction
} from 'react-admin';
import { TimeInput } from './picker';
import { Box } from '@material-ui/core';
import React, { useState, Component } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import IconCancel from '@material-ui/icons/Cancel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MyTime from './MyTime.js';
import MyTab from './mytab.js';

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
const day = String(today.getDate()).padStart(2, '0');

// Format the date as "yyyy-mm-dd"
const todayDt = `${year}-${month}-${day}`;
const validateDate = [required(), minValue(todayDt)];

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const convertToDate = (tm) => {
  if (tm == undefined || tm == "") {
    return "";
  }
  let d = new Date();

  let [hours, minutes] = tm.split(':');

  d.setHours(+hours); // Set the hours, using implicit type coercion
  d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
  return d;
}
function SimpleDialog(props) {
  const classes = useStyles();
  const [{ loading }] = useCreate('posts');
  const [showDialog, setShowDialog] = useState(false);
  const { onClose, open, onCancel } = props;
  const [visibleDates, setvisibleDates] = useState(true);
  const [visibleDate, setvisibleDate] = useState(false);
  const [visibleTime, setvisibleTime] = useState(false);


  //console.log("state of open", open)

  const handleClose = (values) => {
    // console.log("Value for TR", values);
    onClose(values);
  };

  const handleType = (event) => {
    // console.log("TYPE Change ", event.target.value)
    const type = event.target.value
    if (type == "early-pickup" || type == "late-dropoff") {
      setvisibleTime(()=> true);
      setvisibleDate(() => true);
      setvisibleDates(() => false);
    } else {
      setvisibleTime(()=> false);
      setvisibleDate(() => false);
      setvisibleDates(() => true);
    }
  };


  return (
    <Dialog onClose={onCancel} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Enter Student Absence</DialogTitle>
      <FormWithRedirect
        resource="Students"
        save={handleClose}
        render={({
          handleSubmitWithRedirect,
          pristine,
          saving
        }) => (
          <>
            <DialogContent>
              <Box>
                <span><b><u>Tuition is still charged for any absence</u></b></span>
                <Box display="flex">
                  <SelectInput source="Type" validate={required()} choices={[
                    { id: 'absence', name: 'Absence'  },
                    { id: 'early-pickup', name: ' Early Pickup' },
                    { id: 'late-dropoff', name: 'Late DropOff' },
                  ]} onChange={event => handleType(event)} />
                </Box>
                <Box>
                  <TextInput label="Reason for Absence " source="Reason" />
                </Box>
                {visibleDate && (<Box>
                  <DateInput source="fromDt" id="fromDt" label="Date"  validate={validateDate}/>
                </Box>)}
                {visibleDates && (<Box>
                  <DateInput source="fromDt" id="fromDt" label="From" validate={validateDate} /> <MyTab />
                  <DateInput source="toDt" id="toDt" label="To" validate={validateDate} />
                </Box>)}
                {visibleTime && (<Box>
                  <TimeInput source="Time" label="Time" isRequired={true} options={{ clearable: true }}  />
                </Box>)}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} disabled={loading}>
                Cancel<IconCancel />
              </Button>
              <SaveButton
                handleSubmitWithRedirect={
                  handleSubmitWithRedirect
                }
                pristine={pristine}
                saving={saving}
                disabled={loading}
              />
            </DialogActions>
          </>
        )}
      />

    </Dialog >
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

class StudentAbsence extends Component {
  constructor(props) {
    // console.log("PROPS", props);
    super(props);
    this.state = {
      open: false
    };
  }

  handleClickOpen = () => {
    //console.log("open ", this.state.open);
    // this.state.open=true;
    this.setState((state, props) => {
      return { open: true };
    });
    //console.log("In click open", this.state.open)
  };

  handleClickCancel = () => {
    //console.log("cancel ", this.state.open);
    // this.state.open=true;
    this.setState((state, props) => {
      return { open: false };
    });
    //console.log("In click cancel", this.state.open)
  };

  handleClose = (values) => {
    this.setState((state, props) => {
      return { open: false };
    });
    // this.state.selectedValueYear = valueYear;
    // this.state.setSelectedValueSemester = valueSemester;
    const fetchURL = process.env.REACT_APP_GO_CONNECT_URL + "/StudentAbsences"
    //console.log(fetchURL)
    //  const { push, selectedIds, showNotification } = this.props;
    //  //console.log(this.props);
    //console.log("sending " + this.props.selectedIds);
    const token = localStorage.getItem('token');
    const hdrs = new Headers({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token,
      // "Content-Length": JSON.stringify(body).length
    })
    // const bdy="{\"studs\":[{\"id\":\"8bb9e4a0-5387-11ea-8d8c-f8cab832656a\",\"sname\":\"Test04\",\"Sdob\":\"01/01/2018\",\"sdoj\":\"02/10/2019\",\"semail\":\"\",\"eStatus\":\"Active\",\"graduationDate\":\"\",\"schoolID\":3,\"program\":\"fullday\",\"tuition\":\"$875\",\"sex\":\"F\",\"pIN\":\"8976\",\"immunizationStatus\":\"complete\",\"classroom\":\"montessori01\",\"parents\":[{\"pName\":\"parent01\",\"address\":\"abc, AZ 85001\",\"tel\":\"6230019999\",\"email\":\"abc@xyz.com\"},{\"pName\":\"parent02\",\"address\":\"abc, AZ 85002\",\"tel\":\"6230019990\",\"email\":\"abc2@xyz.com\"}]},{\"id\":\"98e64254-537e-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"01/01/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"Graduated\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"9876\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"},{\"id\":\"2dba6ce6-537f-11ea-aab6-f8cab832656a\",\"Sname\":\"newtest01\",\"Sdob\":\"1/1/1999\",\"Sdoj\":\"\",\"Semail\":\"\",\"EStatus\":\"\",\"GraduationDate\":\"\",\"SchoolID\":3,\"Program\":\"Toddler - FullDay\",\"Tuition\":\"\",\"Sex\":\"F\",\"PIN\":\"\",\"ImmunizationStatus\":\"Not Provided\",\"Classroom\":\"Classroom 03\"}]}";
    const params = new Object();
    // params.rcyear = this.state.selectedValueYear;
    // params.rcsemester = this.state.selectedValueSemester;
    values.sid = this.props.record.id;
    values.Name = this.props.record.Sname;
    values.Classroom = this.props.record.Classroom;
    // var d = new Date();
    // var dt = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-'
    //   + ('0' + d.getDate()).slice(-2);
    // values.today = dt;
    const bdy = JSON.stringify(values);
    //console.log(bdy)
    // const updatedRecord = { ...record, is_approved: true };
    fetch(fetchURL, { method: 'POST', body: bdy, headers: hdrs })
      .then(response => {
        // console.log("response", response)
        if (response.ok) {
          response.json().then(json => {
            //console.log(response, json);
            if (json != "") {
              this.props.showNotification("Absence saved");
              this.props.push('/StudentAbsences');

            } else {
              this.props.showNotification("Absence saved");
              this.props.push('/StudentAbsences');
            }
          });
        } else if (response.status === '401') {
          this.props.showNotification("Unauthorized, please login...");
        } else {
          response.json().then(json => {
            console.log(response, json);
            if (json != "") {
              this.props.showNotification("Error saving: " + json.message);

            } else {
              this.props.showNotification("Error saving Student Absence");
            }
          });
        }
      })
      .catch((e) => {
        //console.error(e);
        this.props.showNotification('Error: saving student absence', 'warning')
      });
  };

  render() {
    return <div><Tooltip title="Report Student Absence"><IconButton color="primary" component="span" onClick={this.handleClickOpen}>
      <AssignmentIcon style={{ color: "green" }} />
    </IconButton></Tooltip>
      <SimpleDialog onCancel={this.handleClickCancel} open={this.state.open} onClose={this.handleClose} />

    </div>
  }

}

StudentAbsence.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};
export default connect(null, {
  showNotification: showNotificationAction,
  push: pushAction,
})(StudentAbsence);


