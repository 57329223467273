import React from 'react';
import { useRecordContext } from 'react-admin';

const DisplaySignature = (props) => {
    const record = useRecordContext();
    const src = props.source;
    if (src.includes(".")) {
        const sObj = props.source.split(".");
        // console.log("props ", props.source, sObj)
        // console.log("rec ", record[sObj[0]][sObj[1]][sObj[2]])
        if (record[sObj[0]][sObj[1]][sObj[2]] != "") {
            return (
                < img src={`${record[sObj[0]][sObj[1]][sObj[2]]}`} width="70" height="50" />
            );
        } else {
            return (<div></div>);
        }
    } else {
        if (record != undefined && record[src] != "") {
            return (
                < img src={`${record[src]}`} width="70" height="50" />
            );
        } else {
            return (<div></div>);
        }

    }
}

export default DisplaySignature;