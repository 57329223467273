import { usePermissions,useRedirect } from 'react-admin';
import EmployeeDashboard from './employeedashboard';
// import { Redirect } from "react-router-dom";
import { StudentDashboard } from './studentdashboard';


export const Dashboard = () => {
    const { isLoading, permissions } = usePermissions();
    // const redirect = useRedirect();

    if (isLoading) return null;
    if (permissions != undefined) {
        switch (permissions.role) {
            case 'user':
                return <StudentDashboard {...permissions} />;
            case 'primaryassistant':
            case 'toddlerassistant':
            case 'toddlerteacher':
            case 'teacher':
                return <EmployeeDashboard {...permissions} />;
            default:
                return null;
        }
    } else {
        return null;
    }
};

export default Dashboard;