import React from 'react';
import { ImageField, SimpleList, Filter, BulkDeleteButton, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import MyImage from './myimage.js';
import Typography from '@material-ui/core/Typography';
import ActionsRefresh from './NoAction.js';




const StudentSigninoutFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput allowEmpty={false}alwaysOn label="PIN" source="id" reference="Students" sort="Sname" order="ASC">
            <SelectInput optionText={(record) => { let val = record.PIN + "-" + record.Sname; return val; }} />
        </ReferenceInput>
        <TextInput alwaysOn label="Date" source="siodate" ></TextInput>
    </Filter>
);


var d = new Date();
var dt = ('0' + (d.getMonth() + 1)).slice(-2) + '/'
    + ('0' + d.getDate()).slice(-2) + '/'
    + d.getFullYear();
//console.log("dateeeee", dt.substr(0, dt.indexOf('/')).length, dt)
var mnth = d.getMonth();
var yr = d.getFullYear();
//console.log(mnth)


export const StudentSigninoutList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions !== undefined && permissions.role === 'user') {
    return (
        <List filters={<StudentSigninoutFilter />} filterDefaultValues={{ Month: mnth, Year: yr, siodate: dt }} bulkActionButtons={false} {...props} actions={<ActionsRefresh {...props} />}>
            {isSmall ? (
                <Datagrid>
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Student Name" source="Name" />
                    <TextField label="SignIn Type" source="Status" />
                    <TextField label="Time" source="Time" />
                </Datagrid>
            ) : (
                <Datagrid {...props}>
                    <TextField label="Date" source="SIODate" />
                    <TextField label="Student Name" source="Name" />
                    <TextField label="SignIn Type" source="Status" />
                    <TextField label="Time" source="Time" />
                    <TextField label="Extra Time(in Hrs)" source="EHrs.ExtraTime" />
                    <TextField label="Extra Amount(in $)    " source="EHrs.ExtraAmount" />
                </Datagrid>)}
        </List>
    );
}  else {
    return (<span>User has No Access...</span>)
}
};

const StudentSigninoutTitle = ({ record }) => {
    return <span>Signinout {record ? `"${record.id}"` : ''}</span>;
};
