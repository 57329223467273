import { TextField, List, Datagrid, Labeled } from 'react-admin';
import { Box, Typography } from '@material-ui/core';

export const EmployeeList = (props) => (
    <List bulkActionButtons={false} {...props}>
        <Datagrid >
            <Box p="1em">
                <Box display="flex">
                    <Box >
                        <Typography variant="h6" gutterBottom>Employee Info</Typography>
                        <Box display="flex">
                            <Box mr="2em">
                                <Labeled label="Employee's Name">
                                    <TextField label="Employee Name" source="Name" />
                                </Labeled>
                            </Box>
                            <Box  >
                                <Labeled label="Sex">
                                    <TextField source="Sex" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box mr="2em">
                                <Labeled label="Address">
                                    <TextField label="Address" source="Address" />
                                </Labeled>
                            </Box>
                            <Box mr="2em">
                                <Labeled label="Phone">
                                    <TextField label="Phone" source="Tel" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box mr="2em">
                            <Labeled label="Email">
                                <TextField label="Email" source="Email" />
                            </Labeled>
                        </Box>

                        <Box display="flex">
                            <Box mr="2em" >
                                <Labeled label="Date Of Birth">
                                    <TextField label="Date of Birth" source="Dob" />
                                </Labeled>
                            </Box>
                            <Box >
                                <Labeled label="Date of Joining">
                                    <TextField label="Date of Joining" source="Doj" />
                                </Labeled>
                            </Box>

                        </Box>
                        <Box display="flex">
                            <Box mr="6em" >
                                <Labeled label="Classroom Assigned">
                                    <TextField source="Classroom" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box display="flex">
                            {/* <Box mr="2em" >
                                <TextField label="PIN" source="PIN" validate={validatePIN} />
                            </Box> */}
                            <Box mr="6em" >
                                <Labeled label="Immunization Status">
                                    <TextField label="Immunization Status" source="ImmunizationStatus" />
                                </Labeled>
                            </Box>
                            <Box>
                                <Labeled label="TB Test">
                                    <TextField label="TB Test" source="TBTest" />
                                </Labeled>
                            </Box>
                        </Box>
                        <Box>
                            <Labeled label="CPR Expiry Date">
                                <TextField label="CPR Expiry Date" source="CPRExpiryDate" />
                            </Labeled>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Datagrid>
    </List>
)
