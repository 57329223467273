import React, { useEffect } from 'react';
import { LamsApplicationList, LamsApplicationEdit, LamsApplicationCreate } from './LamsApplication';
import { StudentsList } from './students';
import { StudentAbsencesList } from './studentAbsences';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { useAuth0 } from '@auth0/auth0-react';
import MyLogoutButton from './MyLogoutButton';
import authProvider from './authProvider';
import MyLoginPage from './MyLoginPage';
import { createBrowserHistory as createHistory } from 'history';
import { ReceiptList } from './receipt';
import { StudentSigninoutList } from './studentsigninout';
import { Dashboard } from './dashboard.js';
import { EmployeeList } from './employee.js';


import ReactGA from 'react-ga4';
const TRACKING_ID = "G-89ZMFV5GJL";
ReactGA.initialize(TRACKING_ID);

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.mode = 'cors';
  return fetchUtils.fetchJson(url, options);
}

const providerURL = process.env.REACT_APP_GO_CONNECT_URL
const dataProvider = jsonServerProvider(providerURL, httpClient);
const history = createHistory();



const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  };
  return (
    <Admin dashboard={Dashboard} disableTelemetry history={history} authProvider={authProvider} loginPage={MyLoginPage} logoutButton={MyLogoutButton} dataProvider={dataProvider} >
      {permissions => [
        permissions !== undefined && permissions.role !== null && (permissions.role === 'teacher' || permissions.role === 'toddlerteacher' || permissions.role === 'toddlerassistant' || permissions.role === 'primaryassistant')
          ? <Resource options={{ label: 'Employee Details' }} name="Employees" list={EmployeeList} /> : null ,
          permissions !== undefined && permissions.role !== null  && permissions.role === 'user'
          ? <Resource name="Students" list={StudentsList} /> : null ,
          permissions !== undefined && permissions.role !== null  && permissions.role === 'user' ?
          <Resource name="Applications" list={LamsApplicationList} create={LamsApplicationCreate} edit={LamsApplicationEdit} /> : null,
          permissions !== undefined && permissions.role !== null  && permissions.role === 'user' ?
          <Resource options={{ label: 'Student Attendance' }} name="StudentSignInOut" list={StudentSigninoutList} /> : null,
          permissions !== undefined && permissions.role !== null  && permissions.role === 'user' ?
          <Resource options={{ label: 'Student Absences' }} name="StudentAbsences" list={StudentAbsencesList} /> : null,
      ]
      }
    </Admin>
  );

}

export default App;

